export const getEnvironment = function() {
    const url = window.location.href;
    if (url.includes('localhost')) {
        return 'dev';
    }
    if (url.includes('zonwatt.digicit.nl')) {
        return 'test';
    }
    if (url.includes('-test.zonwatt.nl')) {
        return 'test';
    }
    if (url.includes('zonwatt.nl')) {
        return 'prod';
    }
}

export const uniqueBy = function(a, cond) {
    return a.filter((e, i) => a.findIndex(e2 => cond(e, e2)) === i);
}
