import axios from 'axios';
import {getEnvironment} from './General';

const getBaseUrl = function(){
    const environment = getEnvironment();
    switch(environment) {
        case 'dev':
            return 'https://europe-west1-zonwatt-webhook-dev.cloudfunctions.net/customer';
            break;
        case 'test':
            return 'https://europe-west1-zonwatt-webhook-dev.cloudfunctions.net/customer';
            break;
        case 'prod':
            return 'https://europe-west1-zonwatt-webhook-prod.cloudfunctions.net/customer';
            break;
    }

}

export const customerWithOffers = async function(customerId, token) {
    if (typeof customerId == 'string' && customerId.length > 0 && typeof token == 'string' && token.length > 0) {
        const additionalParameters = {action: 'offers', cid: customerId, token};
        const axiosResult = await axios.get(getBaseUrl(), {params: additionalParameters});
        return axiosResult.data;
    } else {
        return {};
    }
}

export const offerToSurvey = async function(customerId:string, token:string, offerId:string, dateRemarks:string, otherRemarks:string) {
    if (typeof customerId == 'string' && customerId.length > 0 && typeof token == 'string' && token.length > 0 && typeof offerId == 'string' && offerId.length > 0) {
        const additionalParameters = {action: 'toSurvey', cid: customerId, token, offerId, dateRemarks, otherRemarks};
        const axiosResult = await axios.put(getBaseUrl(), additionalParameters);
        return axiosResult.data;
    }

    return {};
}

/**
 * @todo Only added the params for this function
 * @param customerId
 * @param token
 * @param offerId
 * @param acceptOffer
 * @param acceptTerms
 * @param chosenOptions
 * @param chosenAdditionalProducts
 */
export const acceptOffer = async function(customerId:string, token:string, offerId:string, acceptOffer:boolean, acceptTerms:boolean, chosenOptions:object, chosenAdditionalProducts:object, customerName:string, energieLeveren:boolean) {
    console.log('acceptOffer called');
    if (
        typeof customerId == 'string' && customerId.length > 0 &&
        typeof token == 'string' && token.length > 0 &&
        typeof offerId == 'string' && offerId.length > 0
    )
    {
        console.log('Matches criteria');
        const additionalParameters = {action: 'acceptOffer', cid: customerId, token, offerId, acceptOffer, acceptTerms, chosenOptions, chosenAdditionalProducts, customerName, energieLeveren};
        const axiosResult = await axios.put(getBaseUrl(), additionalParameters);
        return axiosResult.data;
    } else {
        console.log('Invalid stuff ofzo');
    }

    return {};
}
