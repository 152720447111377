





















































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import * as easings from 'vuetify/lib/services/goto/easing-patterns'

import {uniqueBy} from "@/services/General";
import {offerToSurvey, acceptOffer} from "@/services/ZonwattApi";

import MoneyRow from '../components/MoneyRow.vue'
import MaterialSpecification from '../components/MaterialSpecification.vue'


export default Vue.extend({
    name: 'Offer',

    components: {
        MoneyRow,
        MaterialSpecification
    },
    data: () => ({
        easings: Object.keys(easings),
        reactionSent: false,
        monthLabels: ['jan','feb','mrt','apr','mei','jun','jul','aug','sep','okt','nov','dec'],
        dateRemarks: '',
        otherRemarks: '',
        singleChoice: null,
        multipleChoice: [],
        additionalProducts: [],
        acceptOffer: null,
        acceptTerms: null,
        energieLeveren: null,
        fullCustomerName: null,
        panels: []
    }),
    computed: {
        offer() {
            if (this.$store.state.currentOfferIndex !== null) {
                let offer = this.$store.state.offers[this.$store.state.currentOfferIndex];


                return offer;
            } else {
                return {}
            }
        },
        offerOptions() {
            let foobar = this.$store.state.currentOfferIndex;
            if (this.offer != {}) {
                let offerOptions = this.offer.options;

                if (this.offer.status == 'confirmed' || this.offer.status == 'installed') {
                    offerOptions = this.offer.options.filter(option => option.chosen == true);
                    return offerOptions;
                }

                return offerOptions;
            }

            return [];
        },
        offerValidFormattedDate: function() {
            const myDate = new Date(this.offer.validUntil);
            return myDate.toLocaleDateString('nl-NL', {day: 'numeric', month: 'long', year: 'numeric'});
        },
        energySavingTitle: function() {
            return 'Berekende energiebesparing op basis van ' + this.offer.energyPrice + ' / kWh';
        },
        offerAcceptable: function() {
            return (
                this.acceptOffer === true &&
                this.acceptTerms === true &&
                (
                    this.offer.options.length == 1 ||
                    (
                        this.offer.options.length > 1 &&
                        (
                            (this.offer.optionMode == 'single' && this.singleChoice !== null) ||
                            (this.offer.optionMode == 'multiple' && this.multipleChoice.length > 0)
                        )
                    )
                ) &&
                typeof this.fullCustomerName === 'string' && this.fullCustomerName.length > 5
            );
        },
        statusDescription: function() {
            if (this.offer != {}) {
                const offer = this.offer;
                if (offer.status == 'new' || offer.status == 'draft' || offer.status == 'awaitingInternalApproval' || offer.status == 'revise') {
                    return 'Offerte wordt voor u aangepast';
                }
                if (offer.status == 'indicativeOffer') {
                    return 'Indicatieve offerte';
                }
                if (offer.status == 'definitiveOffer') {
                    return 'Definitieve offerte';
                }
                if (offer.status == 'approved') {
                    return 'Offerte door u goedgekeurd';
                }
                if (offer.status == 'rejected') {
                    return 'Offerte door u afgewezen';
                }
                if (offer.status == 'superseded') {
                    return 'Offerte vervangen door een nieuwe offerte. Deze offerte is niet meer geldig';
                }
                if (offer.status == 'installed') {
                  return 'Geïnstalleerd';
                }
            }
            return '';
        },
        hasSolaredgeInverter: function() {
            if (this.offer != {}) {
                let solaredge = false;
                this.offer.options.forEach(option => {
                    let optionInverters = option.products.filter(product => (product.type == 'inverter'));
                    optionInverters.forEach(inverter => {
                        /*if (inverter.manufacturer === 'Goodwe') {
                            goodwe = true;
                        }*/
                        if (inverter.manufacturer === 'Solaredge') {
                            solaredge = true;
                        }
                    });
                });

                return solaredge;
            }

            return false;
        },
        hasSplitPayments: function() {
            return (this.offer != {} && typeof this.offer.payments != 'undefined' && this.offer.payments.length > 0);
        },
        installationDate: function() {
            if (this.offer != {} && typeof this.offer.installation != 'undefined' && typeof this.offer.installation.start != 'undefined') {
                let myDate = new Date(this.offer.installation.start);
                let returnable = myDate.toLocaleDateString('nl-NL', {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'});
                returnable = returnable.charAt(0).toUpperCase() + returnable.slice(1);
                if (typeof this.offer.installation.end != 'undefined' && this.offer.installation.end != '' && this.offer.installation.end != this.offer.installation.start) {
                    myDate = new Date(this.offer.installation.end);
                    returnable = returnable + ' - ' + myDate.toLocaleDateString('nl-NL', {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'});
                }
                if (returnable === 'Invalid Date') {
                  return 'Nader te bepalen';
                }
                return returnable;
            }
            return 'Nader te bepalen';
        },
        isOfferExpired() {
            const validStates = ['approved','confirmed', 'installed'];
            return (this.offer.validUntil < new Date().toISOString().split('T')[0]) && !validStates.includes(this.offer.status);
        },
        isOfferTypeNew() {
            return this.offer.type === 'new';
        },
        isOfferTypeCustom() {
            return this.offer.type === 'custom';
        },
        includingVatDescription() {
          return this.offer.totals.salesVatFactor == 1 ? '' : 'incl. BTW';
        },
        showSubtotalExColumnForConfirmedOffer() {
          return this.offer.totals.salesVatFactor != 1
        }
    },
    methods: {
        energieLeverenInverterInfo: function(optionIndex) {
            return this.offer.options[optionIndex].products.filter(product => product.type == 'inverter')[0];
        },
        energieLeverenSolarPanelCount: function(optionIndex) {
            let count = 0;
            console.log('qwofferOptionIndex', optionIndex);
            console.log('qwob', this.offer.options);
            let solarPanels = this.offer.options[optionIndex].products.filter(product => product.type == 'panel' || product.type == 'paneel');
            solarPanels.forEach(panel => {
                count = count + panel.count;
            });
            return count;
        },
        scrollTo: function (htmlElementId) {
            this.$vuetify.goTo('#' + htmlElementId, {duration: 300, offset: 0, easing: 'easeInOutCubic'});
        },
        timestampToDate(timestamp) {
            if (typeof timestamp != 'undefined' && timestamp != '') {
                let myDate = new Date(timestamp);
                return myDate.toLocaleDateString('nl-NL', {day: 'numeric', month: 'long', year: 'numeric'});
            }
        },
        /*optionSolarPanels(optionKey) {
            let returnable = [];
            const solarpanels = this.offer.options[optionKey].products.filter(product => product.type == 'panel');
            solarpanels.forEach((product) => {
                returnable.push({name: product.manufacturer + ' ' + product.name, count: product.count});
            });
            return returnable;
        },*/
        optionMaterialsForType(optionKey, type) {
            let returnable = [];
            const products = this.offer.options[optionKey].products.filter(product => product.type == type);
            if (products.length > 0) {
                products.forEach((product) => {
                    returnable.push({name: product.manufacturer + ' ' + product.name, count: product.count});
                });
            }
            return returnable;
        },
        /**
         * Returns all the materials for an option
         * @param int optionKey
         * @param bool allMaterials if set to true will return all materials in the order
         * @param bool unique will filter to make unique if needed (for displaying the materials with images)
         * @param null option can be provided over here
         */
        optionMaterialsForSpecification(optionKey, allMaterials = false, unique = false, option = null) {
            let returnable = [];
            const productHolder = (option != null ? option.products : this.offer.options[optionKey].products);
            const products = productHolder.filter(product => (product.type == 'panel' || product.type == 'inverter' || product.type == 'optimizer' ||  product.type == 'laadpaal' || product.type == 'montagemateriaal' || (allMaterials === true && product.type == 'material')));
            products.forEach((product) => {
                returnable.push(product);
            });
            if (this.offer.mountingMaterialMode !== 'groupByType') {
                const gridHolder = (option != null ? option.grids : this.offer.options[optionKey].grids);
                gridHolder.forEach(grid => {
                    let gridProducts = grid.products.filter(product => (product.type == 'montagemateriaal'));
                    gridProducts.forEach((gridProduct) => {
                        returnable.push(gridProduct);
                    });
                });
            }
            if (unique) {
                //console.log('uniek', uniqueBy(returnable, (o1, o2) => {o1.id === o2.id}));
                //return [...returnable.reduce((map, obj) => map.has(obj.firstname) ? map : map.set(obj.firstname, obj), new Map()).values()];
                //return returnable;
                return returnable.filter((a, i) => returnable.findIndex((s) => a.id === s.id) === i);
            }
            return returnable;
        },
        /**
         * Returns the optionFile
         * @param optionKey
         * @param reference
         * @param option
         */
        optionFileByReference(optionKey, reference, option = null) {
            const fileOption = (option != null ? option : this.offer.options[optionKey]);
            const file = fileOption.files.filter(item => item.systemReference == reference);
            if (file.length > 0) {
                return file[0];
            } else {
                return {}
            }
        },
        sendReaction() {
            offerToSurvey(this.$route.params.customerId, this.$route.params.token, this.offer.id, this.dateRemarks, this.otherRemarks)
                .then((data) => {
                    this.reactionSent = true;
                });

        },
        offerAccepted() {
            if (this.isOfferExpired && this.offer.status !== 'indicativeOffer') {
                return alert('U kunt deze offerte niet accorderen, omdat hij is verlopen. Neem contact met ons op om uw offerte te laten actualiseren.');
            }
            //First convert single options to multiple
            if (this.offer.options.length == 1) {
                this.multipleChoice.push(0);
            } else if (this.offer.optionMode == 'single') {
                this.multipleChoice.push(this.singleChoice);
            }
            acceptOffer(this.$route.params.customerId, this.$route.params.token, this.offer.id, this.acceptOffer, this.acceptTerms, this.multipleChoice, this.additionalProducts, this.fullCustomerName, this.energieLeveren)
                .then((data) => {
                    this.reactionSent = true;
                })
        },
        vatLabel(vatFactor) {
          if (vatFactor == 1) {
            return '0 %';
          }
          if (vatFactor == 1.09) {
            return '9 %';
          }
          if (vatFactor == 1.21) {
            return '21 %';
          }

          return 'ONBEKEND';
        }
    }
})
